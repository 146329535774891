import request from '../utils/request'
import baseUrl from './baseUrl'

export const userLevelList = (data) => request({
  url: baseUrl + '/userLevel/selectForBack',
  method: 'POST',
  data
})
export const addLevel = (data) => request({
  url: baseUrl + '/userLevel/add',
  method: 'POST',
  data
})
export const editLevel = (data) => request({
  url: baseUrl + '/userLevel/add',
  method: 'POST',
  data
})
//修改头衔
export const changeUserLevel = (data) => request({
  url: baseUrl + '/userLevel/modify',
  method: 'POST',
  data
})
//等级详情
export const levelDetails = (data) => request({
  url: baseUrl + '/userLevel/selectForId',
  method: 'POST',
  data
})
//删除等级 
export const delLevel = (data) => request({
  url: baseUrl + '/userLevel/deleteById',
  method: 'POST',
  data
})
//查看看全部 
export const allLevel = () => request({
  url: baseUrl + '/userLevel/selectAll',
  method: 'POST',
})
