<template>
  <div class="vipList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>会员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <div style="margin-bottom: 30px">
        <span>会员ID:</span>
        <el-input v-model="list.userId" placeholder="请输入会员ID"></el-input>
        <span>真实姓名:</span>
        <el-input v-model="list.trueName" placeholder="请输入真实姓名"></el-input>
        <span>手机号码:</span>
        <el-input v-model="list.phone" placeholder="请输入手机号码"></el-input>

        <span>省份:</span>
        <el-input v-model="list.province" placeholder="请输入会员所在省份"></el-input>

        <span>城市:</span>
        <el-input v-model="list.city" placeholder="请输入会员所在城市"></el-input>
        <br />
        <br />
        <span>会员等级</span>
        <el-select v-model="list.levelId" placeholder="请选择">
          <el-option v-for="item in allLevelList" :key="item.cateId" :label="item.cateName" :value="item.cateId">
          </el-option>
        </el-select>
        <span>会员号:</span>
        <el-input v-model="list.userNo" placeholder="请输入会员号"></el-input>
        <span>店长等级:</span>
        <el-select v-model="list.profitLevelId" placeholder="请选择">
          <el-option v-for="item in profitLevelList" :key="item.cateId" :label="item.cateName" :value="item.cateId">
          </el-option>
        </el-select>
        <span>是否店长</span>
        <el-select v-model="list.isPartner" placeholder="请选择">
          <el-option v-for="item in isParOption" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span>会员来源：</span>
        <el-select v-model="list.source" clearable @clear="onClear" placeholder="请选择来源">
          <el-option v-for="item in paySource" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div>
        <span>活动Id:</span>
        <el-input v-model="list.activityId" placeholder="请输入活动Id" type="number"></el-input>
        <span>注册时间：</span>
        <el-date-picker @change="onChange" v-model="addTime" type="daterange" align="right" unlink-panels
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
          :picker-options="pickerOptions">
        </el-date-picker>
        <span>活动区域Id:</span>
        <el-input v-model="list.areaId" placeholder="请输入活动区域Id" type="number"></el-input>
        <el-button type="primary" @click="getVipList()">查找</el-button>
        <el-button type="primary" @click="createForm()">生成报表</el-button>

        <div style="margin-top: 20px"></div>
      </div>
    </div>
    <el-table :data="vipList" border style="width: 100%">
      <!-- <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column> -->
      <!-- <el-table-column
        align="center"
        prop="detp"
        label="排序"
        width="width"
      ></el-table-column> -->
      <el-table-column align="center" prop="userId" label="用户ID" width="width"></el-table-column>

      <el-table-column align="center" prop="trueName" label="真实姓名" width="width"></el-table-column>
      <el-table-column align="center" prop="" label="用户头像" width="width">
        <template slot-scope="{ row }">
          <el-image style="width: 40px; height: 40px" :src="row.headImgUrl"
            :preview-src-list="[row.headImgUrl]"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="phone" label="手机号码" width="width"></el-table-column>
      <el-table-column align="center" prop="sex" label="性别" width="width"></el-table-column>
      <el-table-column align="center" prop="nickName" label="昵称" width="width"></el-table-column>
      <el-table-column align="center" prop="totalIntegral" label="总积分" width="width"></el-table-column>
      <el-table-column align="center" prop="balance" label="余额"></el-table-column>
      <el-table-column align="center" prop="levelId" label="会员等级" width="150">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" content="点击修改等级" :enterable="false" placement="top">
            <!-- <el-button @click="onchange(row)" v-if="!row.inputShow">{{
              row.levelId
            }}</el-button> -->
            <el-select @change="onSelectChange(row)" v-model="row.levelId" placeholder="请选择">
              <el-option v-for="item in allLevelList" :key="item.cateId" :label="item.cateName" :value="item.cateId">
              </el-option>
            </el-select>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="addTime" label="注册时间" width="width"></el-table-column>
      <el-table-column align="center" prop="staffId" label="地推人员ID" width="width"></el-table-column>
      <el-table-column align="center" prop="activityTitle" label="活动标题" width="width"></el-table-column>
      <el-table-column align="center" prop="areaName" label="区域名称" width="width"></el-table-column>
      <el-table-column align="center" prop="" label="操作" width="250">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onGodetail(row.userId, row.staffId)"
            style="margin-right: 20px">查看详情</el-button>
          <el-dropdown>
            <el-button size="mini" type="primary">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><span @click="onUpdate(row, '修改余额')">修改余额</span></el-dropdown-item>
              <el-dropdown-item><span @click="onUpdate(row, '修改积分')">修改积分</span></el-dropdown-item>
              <el-dropdown-item><span @click="onBalanceRecord(row)">余额记录</span></el-dropdown-item>
              <el-dropdown-item><span @click="oninterRecord(row)">积分记录</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="操作" width="width">
        <template slot-scope="{ row }"> </template>
      </el-table-column> -->
    </el-table>
    <el-dialog :title="title" @close="onColse" :visible.sync="dialogVisible" width="width">
      <el-form ref="form" label-width="80px">
        <el-form-item label="余额">
          <el-input-number :min="0.1" v-model="updateBalance.num" :step="0.01"></el-input-number>
        </el-form-item>
        <el-form-item label="说明">
          <el-input rows="5" type="textarea" v-model="updateBalance.remark" placeholder="请填写说明"></el-input>
        </el-form-item>
        <el-form-item label="说明">
          <el-radio v-model="updateBalance.addOrSubtract" :label="1">增加</el-radio>
          <el-radio v-model="updateBalance.addOrSubtract" :label="2">减少</el-radio>
          <el-radio v-model="updateBalance.addOrSubtract" :label="3">扣税</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateOk">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="list.pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>
</template>
<script>
import { allLevel } from "../../api/level";
import {
  vipList,
  createForm,
  changeLv,
  updateUserBalance,
  updateUserIntegral,
} from "../../api/vip";
import { gradeListApi } from "../../api/wanxiaoShop";
export default {
  name: "vipList",
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dialogVisible: false, //修改积分余额
      inputShow: false,
      value: "",
      vipList: [],
      pagination: {},
      allLevelList: [],
      list: {
        userId: "",
        currentPage: 1,
        pageSize: 5,
        trueName: "",
        phone: "",
        province: "", //省份
        city: "", //城市
        beginTime: "", //注册时间开始
        endTime: "", //注册时间结束
        levelId: null, //会员等级
        isPartner: null, //是否为店长
        profitLevelId: null, //店长等级
        staffId: this.$route.query.staffId,
        source: 0,
        userNo: "",
        activityId: this.$route.query.activityId || 0,
        areaId: this.$route.query.areaId || 0,
      },
      addTime: [],
      paySource: [
        { value: 0, label: "小程序" },
        { value: 1, label: "H5" },
        { value: 2, label: "PC" },
        { value: 3, label: "Android" },
        { value: 4, label: "IOS" },
      ],
      isParOption: [
        { value: -2, label: "全部" },
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
      cateId: null,
      profitLevelList: [],
      updateBalance: {
        userId: null,
        num: null,
        remark: "",
        addOrSubtract: 1,
      },
      title: "",
      precision: null,
    };
  },
  created() {
    this.getVipList();
    this.getAllLv();
    this.getProList();
  },
  methods: {
    onChange() {
      console.log("onchange");
    },
    onClear() {
      this.list.paySource = null;
    },
    //获取店长等级
    async getProList() {
      const { data } = await gradeListApi();
      if (data.code == 0) {
        this.profitLevelList = data.data;
      }
    },
    //生成报表
    async createForm() {
      if (this.addTime.length == "") {
        this.$message.error("请选择注册时间");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let date = +new Date();
        const { data } = await createForm(this.list);
        if (data instanceof Blob) {
          console.log('The data is a Blob object.',77);
        } else {
          console.log('The data is not a Blob object.',88);
        }

        let url = window.URL.createObjectURL(data); //转换文件流未url
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `万旅网小程序V2.0会员报表_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.close();
        // let blob = new Blob([data], { type: "application/vnd.ms-excel" });
        // let downloadElement = document.createElement("a");
        // let href = URL.createObjectURL(blob);
        // downloadElement.href = href;
        // downloadElement.download = `万旅网小程序V20会员报表_${date}`;
        // document.body.appendChild(downloadElement);
        // downloadElement.click();
        // document.body.removeChild(downloadElement);
        // window.URL.revokeObjectURL(href);
      }
    },
    onChange(value) {
      console.log(value);
      if (value) {
        this.list.beginTime = value[0];
        this.list.endTime = value[1];
        this.getVipList();
      } else {
        this.list.beginTime = null;
        this.list.endTime = null;
      }
    },
    //查看余额记录
    onBalanceRecord(row) {
      this.$router.push("/balanceRecord/" + row.userId);
    },
    //查看积分记录
    oninterRecord(row) {
      this.$router.push("/interRecord/" + row.userId);
    },
    //编辑余额积分
    async onUpdateOk() {
      for (const key in this.updateBalance) {
        if (
          this.updateBalance[key] === "" ||
          this.updateBalance[key] === null
        ) {
          return this.$message.error("请把数据填写完整");
        }
      }
      if (this.title === "修改余额") {
        const { data } = await updateUserBalance({
          userId: this.updateBalance.userId,
          balance: this.updateBalance.num,
          remark: this.updateBalance.remark,
          addOrSubtract: this.updateBalance.addOrSubtract,
        });
        if (data.code == 0) {
          this.$message.success(data.msg);
          this.getVipList();
          this.dialogVisible = false;
        } else {
          this.$message.error(data.msg);
          this.dialogVisible = false;
        }
      } else if (this.title === "修改积分") {
        const { data } = await updateUserIntegral({
          userId: this.updateBalance.userId,
          integral: this.updateBalance.num,
          remark: this.updateBalance.remark,
          addOrSubtract: this.updateBalance.addOrSubtract,
        });
        if (data.code == 0) {
          this.$message.success(data.msg);
          this.getVipList();
          this.dialogVisible = false;
        } else {
          this.$message.error(data.msg);
          this.dialogVisible = false;
        }
      }
    },
    onColse() {
      this.updateBalance.userId = null;
      this.updateBalance.num = null;
      this.updateBalance.remark = "";
      this.title = "";
    },
    onUpdate(row, type) {
      this.title = type;
      this.updateBalance.userId = row.userId;
      this.dialogVisible = true;
      if (type === "修改余额") {
        this.updateBalance.num = row.balance;
        this.precision = 0;
      } else {
        this.updateBalance.num = row.totalIntegral;
        this.precision = 0;
      }
    },
    onGodetail(userId, staffId) {
      delete this.$route.query.activityId;
      delete this.$route.query.areaId;
      this.$router.push("/vipdetail/" + userId + "?staffId=" + staffId);
    },
    //获取会员等级列表
    async getAllLv() {
      const { data } = await allLevel();
      if (data.code == 0) {
        this.allLevelList = data.data;
      }
    },
    async getVipList() {
      this.list.activityId = this.list.activityId || 0;
      this.list.areaId = this.list.areaId || 0;
      const { data } = await vipList(this.list);
      if (data.code == 0) {
        data.list.map((item) => {
          item.inputShow = false;
        });
        this.pagination = data.pagination;
        this.vipList = data.list;
      }
    },
    onSelectChange(row) {
      this.$confirm("是否更改等级?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeLv({
            userId: row.userId,
            levelId: row.levelId,
          });
          console.log("alsdkjalskdjlaskjd", row.levelId);
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getVipList();
          } else {
            this.$message({
              type: "warning",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.getVipList();
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onblur(row) {
      setTimeout(() => {
        row.inputShow = false;
      }, 500);
    },
    onchange(row) {
      console.log(1231231);
      row.inputShow = true;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getVipList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getVipList();
    },
  },
};
</script>
<style lang="less" scoped>
.vipList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .search {
    margin-top: 50px;

    span {
      margin: 0 10px 0 40px;
    }

    .el-input {
      width: 200px;
    }

    .el-button {
      margin: 0 20px;
    }

    .el-select {
      width: 150px;
    }
  }

  .el-table {
    margin-top: 50px;

    .el-table__body-wrapper {
      .tbody {
        .el-button {}
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>